@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Inconsolata', monospace;
  }
}

ul {
  list-style-type: none;
  padding-left: 0;
}

ul li::before {
  content: '+';
  display: inline-block;
  width: 20px;
  text-align: center;
}

.cursor {
  animation: blink 1s infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}
